import '../styles/base.sass';

function Projects() {
  return (
    <div className="page">
      <header>
        <span>PROJECTS</span>
      </header>

      <div className="section">
        <div className="title"><a className="title" href="https://github.com/VW-Stephen/HornsOrNoHorns" target="_blank" rel="noreferrer">Horns or No Horns</a></div>
        <div className="sub">
          Ok, I know they're <em>antlers</em> and not <em>horns</em>, but give me a break. This was an intro to image recognition using trail camera pictures to determine if the picture was of
          a buck, or literally anything else. After spending 12+ hours in the woods, sometimes you don't want to look at hundreds of pictures of doe, turkey, or other hunters (you have dinner to cook and
          whiskey to drink!)
        </div>
        <div className="sub">
          The code is available on <a href="https://github.com/VW-Stephen/HornsOrNoHorns" target="_blank" rel="noreferrer">GitHub</a>, with a small writeup. Turns out it works, but the game camera
          manufacturers have already started to integrate these models into their own offerings, so the project might be (in)complete as-is.
        </div>
      </div>

      <div className="section">
        <div className="title"><a className="title" href="https://github.com/VW-Stephen/SpiderScrape" target="_blank" rel="noreferrer">SpiderScrape</a></div>
        <div className="sub">
          A toy bot I wrote to scrape OkCupid profiles. The goal was to perform NLP on the dataset and make assertions about profiles with certain words, sentiment, etc.
        </div>
        <div className="sub">
          This tool has been used in a few different applications, though my favorite is a group of researchers out of the University of Chicago doing mate pairing/preference research. Second place goes
          to the entrepreneurs that wanted to use the bot to automate matchmaking and messaging, as a service (a lofty, if slightly disconcerting goal.)
        </div>
      </div>

    </div>
  )
}

export default Projects;
