import '../styles/resume.sass';

function Resume() {
  return (
    <div className="resume">
      <div className="header">
        <span className="name">Stephen Fischer</span>
        <span className="sub"><a href="mailto:fischer.stephenl@gmail.com">fischer.stephenl@gmail.com</a></span>
        <span className="sub"><a href="/">stephenleefischer.com</a></span>
        <span className="sub">(509) 869-1027</span>
      </div>

      <div className="section">
        <div className="name">
          Education
        </div>
        <div><strong>B.S. Computer Science, Math Minor</strong>, 2011</div>
        <div>University of Idaho, <em>Moscow, Idaho</em></div>
      </div>

      <div className="section">
        <div className="name">
          Work History
        </div>

        <div className="entry">
          <div><strong>Principal Software Developer</strong>, JUNO</div>
          <div><em>December 2020 - Present</em></div>
          <ul>
            <li>Led modernization efforts, moving the core legacy application (built with an in-house framework, single-tenant) to a multi-tenant modern stack:
              <ul>
                <li>Onboarding new clients went from days/weeks to a single click.</li>
                <li>Substantially improved infrastructure/related costs.</li>
                <li>Allowed clients to scale users 10-100x compared to legacy systems.</li>
              </ul>
            </li>
            <li>As a principal developer, managed and mentored teams of junior developers for a variety of projects:
              <ul>
                <li>Customer content integrations.</li>
                <li>Expanded product offerings, including online learning, analytics, and more.</li>
              </ul>
            </li>
          </ul>

          <div className="tag-list">
            <span className="tag">Python</span>
            <span className="tag">Django</span>
            <span className="tag">Celery</span>
            <span className="tag">Redis</span>
            <span className="tag">PostgreSQL</span>
          </div>


        </div>
        <hr/>

        <div className="entry">
          <div><strong>Senior Web Developer</strong>, NextIT</div>
          <div><em>October 2017 - December 2020</em></div>
          <ul>
            <li>Built and maintained tools to manage and classify live chat data for creating Interactive Virtual Assistants (IVAs).</li>
            <li>Created interactive dashboards for analyzing live IVA data to identify and resolve problems relating to interactive chat.</li>
            <li>Worked closely with business analysts to identify pain points in existing IVA workflows, architect technical solutions, and implement the solutions as new or updated features.</li>
          </ul>
          <div className="tag-list">
            <span className="tag">Python</span>
            <span className="tag">Django</span>
            <span className="tag">Flask</span>
            <span className="tag">Celery</span>
            <span className="tag">Redis</span>
            <span className="tag">PostgreSQL</span>
            <span className="tag">Elasticsearch</span>
            <span className="tag">MongoDB</span>
            <span className="tag">Vue.js</span>
          </div>
        </div>
        <hr/>

        <div className="entry">
          <div><strong>Lead Software Developer</strong>, ClearCare Online</div>
          <div><em>July 2014 - May 2016</em></div>
          <ul>
            <li>Lead small teams of engineers in the design and development of key features and initiatives as determined by collaboration between product and engineering.</li>
            <li>Developed full stack applications and services scalable to millions of page views a month using Django/Flask, backed by Elasticsearch/PostgreSQL/Redis.</li>
            <li>Headed development for the Quality of Care initiative, a collaboration between ClearCare and the Harvard Medical School to preemptively detect changes of a patient's condition and monitor/manage these changes in home (and not the Emergency Room).</li>
            <li>Integrated with third party background check providers to seamlessly include their oerings when performing hiring validations for new/existing employees.</li>
            <li>Created <em>clearcarejobs.com</em>, a job sourcing site for home healthcare agencies.</li>
          </ul>
          <div className="tag-list">
            <span className="tag">Python</span>
            <span className="tag">Django</span>
            <span className="tag">Flask</span>
            <span className="tag">PostgreSQL</span>
            <span className="tag">Elasticsearch</span>
          </div>
        </div>
        <hr/>

        <div className="entry">
          <div><strong>Web Developer, Computing Grid Business Unit</strong>, Genetic Finance</div>
          <div><em>June 2013 - June 2014</em></div>
          <ul>
            <li>Created web services for communicating to/from a computing grid consisting of millions of nodes.</li>
            <li>Developed public facing management portals in PHP/Laravel that allowed customers to view/manage their data.</li>
            <li>Developed internal operations tools that allow for simpler/more reliable data management.</li>
            <li>Designed/implemented fully responsive, public facing corporate websites.</li>
            <li>Created web services for communicating to/from the computing grid.</li>
          </ul>
          <div className="tag-list">
            <span className="tag">PHP</span>
            <span className="tag">Laravel</span>
            <span className="tag">WordPress</span>
            <span className="tag">R</span>
          </div>
        </div>
        <hr/>

        <div className="entry">
          <div><strong>Software Developer</strong>, HubWorks Interactive</div>
          <div><em>September 2011 - May 2013</em></div>
          <ul>
            <li>Developed backend software systems for integration with iPad client device.</li>
            <li>Designed to be highly scalable, able to support thousands of clients at any given time.</li>
            <li>Integrated with multiple point of sale systems to allow ordering/payment via iPad client devices.</li>
            <li>Worked closely with clients to develop robust, easy to use management tools.</li>
          </ul>
          <div className="tag-list">
            <span className="tag">C#</span>
            <span className="tag">.NET</span>
            <span className="tag">Objective-C</span>
          </div>
        </div>
        <hr/>

        <div className="entry">
          <div><strong>Web Application Developer</strong>, University of Idaho</div>
          <div><em>July 2008 - January 2010</em></div>
          <ul>
            <li>Integrated third party hardware video encoders with custom hardware/software written in C#/.NET to create an automated video encoding/delivery system for classrooms.</li>
            <li>Established a fully automated, distributed video transcoding system that delivered video to distance students 45 minutes after recording ended on campus.</li>
          </ul>
          <div className="tag-list">
            <span className="tag">C#</span>
            <span className="tag">.NET</span>
            <span className="tag">ffmpeg</span>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Resume;
