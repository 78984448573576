import '../styles/base.sass';


function Home() {
  return (
    <div className="page">
      <header>
        <span>HEY, I'M STEPHEN</span>
      </header>

      <div className="section home">
        <div className="title"><a className="title" href="/resume">... and I'm a software developer.</a></div>
        <div className="sub">
          I've been doing it professionally for almost two decades, check out <a href="/resume">my resume</a> if you're into that kind of thing.
        </div>
        <div className="sub">
          Or check out some of the <a href="/projects">projects I've been working on</a>, past and present.
        </div>
        <div className="sub">
          I'm also open to work, and you can always <a href="mailto:fischer.stephenl@gmail.com">drop me a line</a> and we'll see if there are any sparks!
        </div>
      </div>

      <div className="section home">
        <div className="title"><a className="title disabled" href="/">... and I do some casual woodworking.</a></div>
        <div className="sub">
          Like every nerd that gets paid to look at screens all day, I enjoy having hobbies in the <em>real</em> world. If I build something I'm pumped on, I'll <a className="disabled" href="/"> probably share it</a>.
        </div>
      </div>

      <div className="section home">
        <div className="title"><a className="title disabled" href="/">... and I like to hunt.</a></div>
        <div className="sub">
          I've written about it a bit, maybe <a className="disabled" href="/">check it out</a>?
        </div>
      </div>


    </div>
  );
}

export default Home;
